var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wenshu" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("van-icon", {
            staticClass: "arrow",
            attrs: { name: "arrow-left" },
            on: { click: _vm.goback },
          }),
          _vm.icon_shai
            ? _c("div", { staticClass: "document" }, [_vm._v("我的文书")])
            : _vm._e(),
          _c("div", { staticClass: "searchborder" }, [
            _vm._m(0),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputsearch,
                  expression: "inputsearch",
                },
              ],
              ref: "input",
              attrs: { type: "text", placeholder: "请输入搜索内容" },
              domProps: { value: _vm.inputsearch },
              on: {
                blur: _vm.gobutton,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.inputsearch = $event.target.value
                },
              },
            }),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "0.4rem",
                cursor: "pointer",
                "margin-left": "1.5rem",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/user/mydocument")
                },
              },
            },
            [_vm._v(" 按案件 ")]
          ),
          _vm.sorthidden
            ? _c("ul", { staticClass: "sort" }, [
                _c(
                  "li",
                  {
                    class: [_vm.active === 0 ? "addclass" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.creatime(0)
                      },
                    },
                  },
                  [_vm._v("全部")]
                ),
                _c(
                  "li",
                  {
                    class: [_vm.active === 1 ? "addclass" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.creatime(1)
                      },
                    },
                  },
                  [_vm._v("按创建时间")]
                ),
                _c(
                  "li",
                  {
                    class: [_vm.active === 2 ? "addclass" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.creatime(2)
                      },
                    },
                  },
                  [_vm._v("按更新时间")]
                ),
                _c(
                  "li",
                  {
                    class: [_vm.active === 3 ? "addclass" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.creatime(3)
                      },
                    },
                  },
                  [_vm._v("按案件")]
                ),
              ])
            : _vm._e(),
          !_vm.icon_shai
            ? _c("div", { staticClass: "searchborder" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputsearch,
                      expression: "inputsearch",
                    },
                  ],
                  ref: "input",
                  attrs: {
                    id: "q",
                    type: "text",
                    placeholder: "请输入搜索内容",
                  },
                  domProps: { value: _vm.inputsearch },
                  on: {
                    blur: _vm.gobutton,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.inputsearch = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("ul", { staticClass: "contentlist" }, [
        _c(
          "li",
          { on: { click: _vm.bootcanp } },
          [
            _c("checkbox", {
              attrs: { cancel: _vm.cancel },
              model: {
                value: _vm.operation,
                callback: function ($$v) {
                  _vm.operation = $$v
                },
                expression: "operation",
              },
            }),
          ],
          1
        ),
        _vm.select.length === 0 ? _c("li", [_vm._v("案件")]) : _vm._e(),
        _vm.select.length === 0 ? _c("li", [_vm._v("收藏时间")]) : _vm._e(),
        _vm.select.length === 0 ? _c("li", {}, [_vm._v("个数")]) : _vm._e(),
        _vm.select.length > 0
          ? _c("li", [_vm._v("已选" + _vm._s(_vm.select.length))])
          : _vm._e(),
        _vm.select.length > 0
          ? _c("li", { on: { click: _vm.depiet } }, [_vm._v("删除")])
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "content",
          on: {
            scroll: function ($event) {
              return _vm.getload($event)
            },
          },
        },
        _vm._l(_vm.getdocument, function (item, index) {
          return _c("ul", { key: index, staticClass: "voncent" }, [
            _c("li", [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.change(item, index)
                    },
                  },
                },
                [
                  _c("checkbox", {
                    model: {
                      value: item.flag,
                      callback: function ($$v) {
                        _vm.$set(item, "flag", $$v)
                      },
                      expression: "item.flag",
                    },
                  }),
                ],
                1
              ),
              _c("div", [_vm._v(" " + _vm._s(item.caseName) + " ")]),
              _c("div", [_c("span", [_vm._v(_vm._s(item.lastUpdateTime))])]),
              _c(
                "div",
                {
                  attrs: { onselectstart: "return false" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.history_edtion(index, item.caseId)
                    },
                  },
                },
                [_vm._v(" (" + _vm._s(item.documentNum) + ") ")]
              ),
            ]),
            item.numhis
              ? _c(
                  "li",
                  {
                    ref: "his_ref",
                    refInFor: true,
                    staticClass: "timehistory",
                    on: {
                      scroll: function ($event) {
                        return _vm.his_scroll($event, index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      _vm._l(item.data, function (itemr, indexr) {
                        return _c(
                          "ul",
                          {
                            key: indexr + "info",
                            on: {
                              click: function ($event) {
                                return _vm.desktop(itemr)
                              },
                            },
                          },
                          [
                            _c("li", [
                              _c("span", [_vm._v(_vm._s(itemr.documentName))]),
                            ]),
                            _c("li", [
                              _vm._v(" " + _vm._s(itemr.updateTime) + " "),
                            ]),
                            _c("li", { style: {} }, [
                              _c("i", {
                                staticClass: "iconfont icon-child-off",
                              }),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ])
        }),
        0
      ),
      _c(
        "van-popup",
        {
          style: { height: "100%", width: "70%" },
          attrs: { position: "right" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", [
            _c("ul", { staticClass: "catlist" }, [
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c("li", { on: { click: _vm.canscreen } }, [_vm._v("取消筛选")]),
              _c("li", { on: { click: _vm.sx } }, [_vm._v("确定")]),
            ]),
            _c("ul", { staticClass: "screen" }, [
              _c("li", [
                _c("div", [_vm._v("时间")]),
                _c("div", [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showdate = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.date.startTime))]
                  ),
                  _c("span", [_vm._v(" - ")]),
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          _vm.enddate = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.date.endTime))]
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "typedata" },
              [
                _c(
                  "van-collapse",
                  {
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c(
                      "van-collapse-item",
                      { attrs: { title: "文书类型", name: "1" } },
                      [
                        _c(
                          "van-radio-group",
                          {
                            model: {
                              value: _vm.radio1,
                              callback: function ($$v) {
                                _vm.radio1 = $$v
                              },
                              expression: "radio1",
                            },
                          },
                          [
                            _c(
                              "van-cell-group",
                              _vm._l(
                                _vm.datalist.documentNameList,
                                function (item, index) {
                                  return _c(
                                    "van-cell",
                                    {
                                      key: index,
                                      attrs: { title: item, clickable: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.radio1 = index + 1
                                        },
                                      },
                                    },
                                    [
                                      _c("van-radio", {
                                        attrs: {
                                          slot: "right-icon",
                                          name: index + 1,
                                        },
                                        slot: "right-icon",
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "van-collapse-item",
                      { attrs: { title: "案由", name: "2" } },
                      [
                        _c(
                          "van-radio-group",
                          {
                            model: {
                              value: _vm.radio2,
                              callback: function ($$v) {
                                _vm.radio2 = $$v
                              },
                              expression: "radio2",
                            },
                          },
                          [
                            _c(
                              "van-cell-group",
                              _vm._l(
                                _vm.datalist.causeNameList,
                                function (item, index) {
                                  return _c(
                                    "van-cell",
                                    {
                                      key: index,
                                      attrs: { title: item },
                                      on: {
                                        click: function ($event) {
                                          _vm.radio2 = index + 1
                                        },
                                      },
                                    },
                                    [
                                      _c("van-radio", {
                                        attrs: {
                                          slot: "right-icon",
                                          name: index + 1,
                                        },
                                        slot: "right-icon",
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "van-collapse-item",
                      { attrs: { title: "案件", name: "3" } },
                      [
                        _c(
                          "van-radio-group",
                          {
                            model: {
                              value: _vm.radio3,
                              callback: function ($$v) {
                                _vm.radio3 = $$v
                              },
                              expression: "radio3",
                            },
                          },
                          [
                            _c(
                              "van-cell-group",
                              _vm._l(
                                _vm.datalist.caseNameList,
                                function (item, index) {
                                  return _c(
                                    "van-cell",
                                    {
                                      key: index,
                                      attrs: { title: item },
                                      on: {
                                        click: function ($event) {
                                          _vm.radio3 = index + 1
                                        },
                                      },
                                    },
                                    [
                                      _c("van-radio", {
                                        attrs: {
                                          slot: "right-icon",
                                          name: index + 1,
                                        },
                                        slot: "right-icon",
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "van-collapse-item",
                      { attrs: { title: "发起人", name: "4" } },
                      [
                        _c(
                          "van-radio-group",
                          {
                            model: {
                              value: _vm.radio4,
                              callback: function ($$v) {
                                _vm.radio4 = $$v
                              },
                              expression: "radio4",
                            },
                          },
                          [
                            _c(
                              "van-cell-group",
                              _vm._l(
                                _vm.datalist.editorList,
                                function (item, index) {
                                  return _c(
                                    "van-cell",
                                    {
                                      key: index,
                                      attrs: { title: item },
                                      on: {
                                        click: function ($event) {
                                          _vm.radio4 = index + 1
                                        },
                                      },
                                    },
                                    [
                                      _c("van-radio", {
                                        attrs: {
                                          slot: "right-icon",
                                          name: index + 1,
                                        },
                                        slot: "right-icon",
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "van-popup",
        {
          style: { height: "30%", width: "100%" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.showdate,
            callback: function ($$v) {
              _vm.showdate = $$v
            },
            expression: "showdate",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: { type: "date" },
            on: { confirm: _vm.confirm, cancel: _vm.tocancel },
            model: {
              value: _vm.currentDate,
              callback: function ($$v) {
                _vm.currentDate = $$v
              },
              expression: "currentDate",
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { height: "30%", width: "100%" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.enddate,
            callback: function ($$v) {
              _vm.enddate = $$v
            },
            expression: "enddate",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: { type: "date" },
            on: { confirm: _vm.endconfirm, cancel: _vm.endcancel },
            model: {
              value: _vm.endcurrentDate,
              callback: function ($$v) {
                _vm.endcurrentDate = $$v
              },
              expression: "endcurrentDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticStyle: { "margin-left": "0.2rem", "font-size": "0.3rem" },
        attrs: { for: "q", id: "q_label" },
      },
      [_c("i", { staticClass: "iconfont icon-02 search" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }