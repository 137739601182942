var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "checkbox",
        style: {
          background:
            (_vm.cancel && _vm.cancel !== null) || _vm.flag ? "#4b9efb" : "",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("changeflag", !_vm.flag)
          },
        },
      },
      [
        _vm.flag || !_vm.cancel ? _c("div", { staticClass: "gou" }) : _vm._e(),
        _vm.cancel && _vm.cancel !== null
          ? _c("div", { staticClass: "hen" })
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }