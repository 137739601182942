<template>
  <div>
    <div class="checkbox"
         :style="{'background':cancel&&cancel!==null||flag?'#4b9efb':''}"
         @click="$emit('changeflag',!flag)">
          <!-- <div   class="forbidden" v-if="isSelected==true">x</div> -->
      <div class="gou"
           v-if='flag||!cancel'></div>
      <div class="hen"
           v-if="cancel&&cancel!==null"></div>

    </div>
  </div>
</template>

<script>
export default {
  props: ['flag', 'cancel', 'isSelected'],
  model: {
    prop: 'flag',
    event: 'changeflag'
  }
}
</script>

<style lang="stylus" scoped>
.checkbox
  width 15px
  height 15px
  border 1px solid #cccccc
  border-radius 2px
  position relative

  .gou
    width 10px
    height 6px
    border 1px solid #ffffff
    border-top none
    border-right none
    position absolute
    left calc(50% - 5px)
    top calc(50% - 5px)
    transition all 0.3s
    transform rotate(-45deg)
  .hen
    width 10px
    height 6px
    color #ffffff
    position absolute
    left calc(50% - 5px)
    top calc(50% - 1px)
    border-top 1px solid #ffffff
</style>
