<template>
  <div class="wenshu">
    <div class="title">
      <van-icon name="arrow-left" class="arrow" @click="goback" />
      <div class="document" v-if="icon_shai">我的文书</div>
      <div class="searchborder">
        <label style="margin-left: 0.2rem; font-size: 0.3rem" for="q" id="q_label">
          <i class="iconfont icon-02 search"></i>
        </label>
        <input
          type="text"
          placeholder="请输入搜索内容"
          @blur="gobutton"
          ref="input"
          v-model="inputsearch"
        />
      </div>

      <div
        @click="$router.push('/user/mydocument')"
        style="font-size: 0.4rem; cursor: pointer; margin-left: 1.5rem"
      >
        按案件
      </div>
      <!-- <i class="iconfont icon-02 search"
         @click="swit_icon"
         v-if='icon_shai'></i>
      <i class='iconfont icon-sort'
         @click="sorthidden=!sorthidden"
         v-if='icon_shai'></i>
      <i class="iconfont icon-shaixuan"
         @click="ssxuan"
         v-if='icon_shai'></i> -->
      <ul class="sort" v-if="sorthidden">
        <li @click="creatime(0)" :class="[active === 0 ? 'addclass' : '']">全部</li>
        <li @click="creatime(1)" :class="[active === 1 ? 'addclass' : '']">按创建时间</li>
        <li @click="creatime(2)" :class="[active === 2 ? 'addclass' : '']">按更新时间</li>
        <li @click="creatime(3)" :class="[active === 3 ? 'addclass' : '']">按案件</li>
      </ul>
      <div class="searchborder" v-if="!icon_shai">
        <input
          id="q"
          type="text"
          placeholder="请输入搜索内容"
          @blur="gobutton"
          ref="input"
          v-model="inputsearch"
        />
      </div>
    </div>
    <!-- 全选 下载 删除操作 -->
    <ul class="contentlist">
      <li @click="bootcanp">
        <checkbox v-model="operation" :cancel="cancel"></checkbox>
      </li>
      <li v-if="select.length === 0">案件</li>
      <li v-if="select.length === 0">收藏时间</li>
      <li style="" v-if="select.length === 0">个数</li>
      <li v-if="select.length > 0">已选{{ select.length }}</li>
      <li v-if="select.length > 0" @click="depiet">删除</li>
    </ul>
    <!-- 获取列表 -->
    <div class="content" @scroll="getload($event)">
      <ul class="voncent" v-for="(item, index) in getdocument" :key="index">
        <li>
          <div @click.stop="change(item, index)">
            <checkbox v-model="item.flag"></checkbox>
          </div>
          <div>
            {{ item.caseName }}
            <!-- {{item.documentName}} -->
          </div>
          <div>
            <span>{{ item.lastUpdateTime }}</span>
          </div>
          <div
            onselectstart="return false"
            @click.stop="history_edtion(index, item.caseId)"
          >
            ({{ item.documentNum }})
          </div>
        </li>
        <!-- 展开历史记录 -->
        <li
          class="timehistory"
          v-if="item.numhis"
          @scroll="his_scroll($event, index)"
          ref="his_ref"
        >
          <div>
            <!-- @click.stop="his_desk(itemr)"  -->
            <ul
              v-for="(itemr, indexr) in item.data"
              :key="indexr + 'info'"
              @click="desktop(itemr)"
            >
              <li>
                <span>{{ itemr.documentName }}</span>
              </li>
              <li>
                {{ itemr.updateTime }}
              </li>
              <!-- <li class="cirlearea">
                  <div class="cirle"
                       v-show="itemr.editor">{{itemr.editor?itemr.editor.slice(-2):''}}</div>
                </li> -->
              <li :style="{}">
                <i class="iconfont icon-child-off"></i>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <van-popup v-model="show" position="right" :style="{ height: '100%', width: '70%' }">
      <div>
        <ul class="catlist">
          <li @click="show = false">取消</li>
          <li @click="canscreen">取消筛选</li>
          <li @click="sx">确定</li>
        </ul>
        <ul class="screen">
          <li>
            <div>时间</div>
            <div>
              <span @click="showdate = true">{{ date.startTime }}</span>
              <span> - </span>
              <span @click="enddate = true">{{ date.endTime }}</span>
            </div>
          </li>
        </ul>
        <div class="typedata">
          <van-collapse v-model="activeNames">
            <van-collapse-item title="文书类型" name="1">
              <van-radio-group v-model="radio1">
                <van-cell-group>
                  <van-cell
                    :title="item"
                    clickable
                    @click="radio1 = index + 1"
                    v-for="(item, index) in datalist.documentNameList"
                    :key="index"
                  >
                    <van-radio slot="right-icon" :name="index + 1" />
                  </van-cell>
                </van-cell-group>
              </van-radio-group>
            </van-collapse-item>
            <van-collapse-item title="案由" name="2">
              <van-radio-group v-model="radio2">
                <van-cell-group>
                  <van-cell
                    :title="item"
                    @click="radio2 = index + 1"
                    v-for="(item, index) in datalist.causeNameList"
                    :key="index"
                  >
                    <van-radio slot="right-icon" :name="index + 1" />
                  </van-cell>
                </van-cell-group>
              </van-radio-group>
            </van-collapse-item>
            <van-collapse-item title="案件" name="3">
              <van-radio-group v-model="radio3">
                <van-cell-group>
                  <van-cell
                    :title="item"
                    v-for="(item, index) in datalist.caseNameList"
                    @click="radio3 = index + 1"
                    :key="index"
                  >
                    <van-radio slot="right-icon" :name="index + 1" />
                  </van-cell>
                </van-cell-group>
              </van-radio-group>
            </van-collapse-item>
            <van-collapse-item title="发起人" name="4">
              <van-radio-group v-model="radio4">
                <van-cell-group>
                  <van-cell
                    :title="item"
                    v-for="(item, index) in datalist.editorList"
                    @click="radio4 = index + 1"
                    :key="index"
                  >
                    <van-radio slot="right-icon" :name="index + 1" />
                  </van-cell>
                </van-cell-group>
              </van-radio-group>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </van-popup>
    <!-- 开始时间 -->
    <van-popup
      v-model="showdate"
      position="bottom"
      :style="{ height: '30%', width: '100%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        @confirm="confirm"
        @cancel="tocancel"
      />
    </van-popup>
    <!-- 结束时间 -->
    <van-popup
      v-model="enddate"
      position="bottom"
      :style="{ height: '30%', width: '100%' }"
    >
      <van-datetime-picker
        v-model="endcurrentDate"
        type="date"
        @confirm="endconfirm"
        @cancel="endcancel"
      />
    </van-popup>
    <!-- <ul>
      <div>此处为网盘接口获取的数据</div>
      <li v-for="(item,index) in getwangpan"
          :key="index">{{item.name}}</li>
    </ul> -->
  </div>
</template>
<script>
import { caseBase } from '~api-config'
import goBackMixin from '@mixins/goBack'
import { setTimeout } from 'timers'
import checkbox from '../../skyDrive/compoments/checkbox'
// import { mapMutations } from 'vuex'
export default {
  mixins: [goBackMixin],
  components: { checkbox },
  name: 'my-document',
  filters: {
    // 开始时间格式化   年：月：日  时：分
    DateTime: function (value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      const hours = beginTime.getHours()
      const minutes = beginTime.getMinutes()
      return (
        year +
        '.' +
        (month > 9 ? month : '0' + month) +
        '.' +
        (day > 9 ? day : '0' + day) +
        ' ' +
        (hours > 9 ? hours : '0' + hours) +
        ':' +
        (minutes > 9 ? minutes : '0' + minutes)
      )
    },
    // 年：月：日
    GetDate: function (value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      return (
        year + '.' + (month > 9 ? month : '0' + month) + '.' + (day > 9 ? day : '0' + day)
      )
    },
    NumberFormat: function (value) {
      return (value * 100).toFixed(2) + '%'
    },
  },
  data() {
    return {
      // getwangpan: [],
      radio1: 0,
      radio2: 0,
      radio3: 0,
      radio4: 0,
      activeNames: ['0'],
      icon_shai: true,
      screen: {
        startTime: '', // 开始时间
        endTime: '', // 结束时间
        documentNames: '', // 文书类型
        editors: '', // 发起人
        causeNames: '', // 案由
        caseNames: '', // 案件
      },
      date: {
        startTime: '开始时间', // 开始时间
        endTime: '结束时间', // 结束时间
      },
      operation: false, // 增删改查的checkbox
      cancel: null,
      sorthidden: false,
      showdate: false,
      enddate: false,
      currentDate: new Date(),
      endcurrentDate: new Date(),
      show: false,
      call: false,
      result: [],
      ss: false,
      inputsearch: '',
      isshowsearch: false,
      documentList: [],
      num: 1,
      checked: [],
      checkedlist: [],
      ischecked: false,
      isallchecked: false,
      loading: false,
      finished: false,
      list: [],
      ls: [],
      number: 0,
      deletelist: [],
      isdelete: false,
      doclist: [],
      isshowtitle: true,
      load: false,
      arr: [],
      is: false,
      sortnum: 1,
      sortOrder: 'DESC',
      getnum: 1,
      getdocument: [],
      select: [],
      hatin: true,
      datalist: [],
      active: 0,
      type: 'updateTime',
      sxx: false, // 为true时为筛选状态
    }
  },
  watch: {
    inputsearch(val) {
      this.getnum = 1
      this.getdocument = []
      setTimeout(() => {
        this.getlist(val)
      }, 200)
    },
    getdocument: {
      deep: true,
      handler(val) {
        this.select = []
        val.forEach((item) => {
          if (item.flag) {
            this.select.push(item)
          }
        })
        if (this.select.length === this.getdocument.length) {
          this.operation = true
          this.cancel = false
        } else {
          this.getdocument.forEach((item) => {
            if (item.flag) {
              this.cancel = true
              this.operation = false
            }
          })
        }
        if (this.select.length === 0) {
          this.operation = false
          this.cancel = false
        }
      },
    },
  },
  methods: {
    his_scroll(e, index) {
      if (
        (e.target.scrollTop + e.target.offsetHeight).toFixed() >= e.target.scrollHeight &&
        this.num_scroll
      ) {
        this.getdocument[index].page++
        this.getData(index, this.getdocument[index].caseId)
      }
    },
    // 展开历史版本
    history_edtion(index, caseId) {
      console.log('23432')
      this.num_scroll = true
      // 展开历史版本
      // if (this.alllately[index].displayHistorical === 'YES') {
      //   this.alllately[index].numhis = !this.alllately[index].numhis
      //   if (this.alllately[index].data.length === 0) {
      //     this.getData(index, caseId, myDocumentId)
      //   }
      // }
      console.log(this.getdocument[index].numhis)
      this.getdocument[index].numhis = !this.getdocument[index].numhis
      console.log(this.getdocument[index].numhis)

      if (this.getdocument[index].data.length === 0) {
        this.getData(index, caseId)
      }
    },
    getData(index, caseId) {
      if (!this.getdocument[index].page) {
        this.getdocument[index].page = 0
      }
      this.$axios
        .get(
          `${caseBase}/document/basicDocuments/getRecentDoc4?caseId=${caseId}&pageNum=${
            this.getdocument[index].page + 1
          }&limit=99`
        )
        .then((res) => {
          res.data.data.items.forEach((item, index) => {
            // const created = new Date(item.updateTime)

            const time = new Date(item.updateTime)
            item.updateTime = `${time.getFullYear()}.${
              time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
            }.${time.getDate() < 10 ? '0' + time.getDate() : time.getDate()}`
          })

          if (res.data.data.items.length === 0) {
            this.num_scroll = false
          } else {
            // if(!this.getdocument[index].data){
            //   this.getdocument[index].data=res.data.data.items
            //   console.log(this.getdocument[index].data)
            // }else{
            this.getdocument[index].data = this.getdocument[index].data.concat(
              res.data.data.items
            )
            // }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // ...mapMutations('user', ['SetCacheCompoment']),
    desktop(item) {
      const {
        documentId,
        groupId,
        identityKey,
        independentDocumentId,
        myDocumentId,
        caseId,
      } = item
      this.$router.push({
        name: 'Readonly',
        query: {
          myDocumentId: myDocumentId || '',
          groupId: groupId || '',
          documentId: documentId || '',
          identityKey: identityKey || '',
          independentDocumentId: independentDocumentId || '',
          caseId: caseId || '',
        },
      })
    },
    sx() {
      this.getnum = 1
      this.getdocument = []
      // 根据条件筛选列表
      const index = parseInt(this.radio1 - 1)
      this.screen.documentNames = this.datalist.documentNameList[index]
      this.screen.causeNames = this.datalist.causeNameList[this.radio2 - 1]
      this.screen.caseNames = this.datalist.caseNameList[this.radio3 - 1]
      this.screen.editors = this.datalist.editorList[this.radio4 - 1]
      this.screen.startTime =
        this.date.startTime === '开始时间' ? '' : this.date.startTime
      this.screen.endTime = this.date.endTime === '结束时间' ? '' : this.date.endTime
      this.getlist()
      this.show = false
    },
    // 取消筛选
    canscreen() {
      this.date.startTime = '开始时间'
      this.date.endTime = '结束时间'
      this.radio1 = 0
      this.radio2 = 0
      this.radio3 = 0
      this.radio4 = 0
    },
    // ssxuan () {
    //   this.show = true
    // },
    // swit_icon () {
    //   // 切换为搜索状态
    //   this.icon_shai = false
    //   setTimeout(() => {
    //     this.$refs.input.focus()
    //     this.$refs.input.select()
    //   }, 100)
    // },
    depiet() {
      // 删除文书
      this.$dialog
        .confirm({
          title: '提示',
          message: '是否要删除该文书',
        })
        .then(() => {
          const arr = []
          this.select.forEach((item) => {
            arr.push(item.caseId)
          })
          // this.$axios.delete(`${caseBase}/document/basicCatalog/deleteMyDocument?myDocumentIdList=${arr.join(',')}`)
          console.log('arr', arr)
          this.$axios
            .delete(
              `${caseBase}/document/basicCatalog/delectDocumentByCaseId?caseIds=${arr.join(
                ','
              )}&deleteHistoricalEdition=YES`
            )
            .then((res) => {
              if (res.data.code === '200') {
                arr.forEach((item) => {
                  const num = this.getdocument.findIndex((itemr) => {
                    return itemr.myDocumentId === item
                  })
                  this.getdocument.splice(num, 1)
                })
                this.$notify({
                  message: '删除文书成功',
                  duration: 1000,
                  background: '#1989fa',
                })
              } else {
                this.$notify(res.data.message)
              }
            })
        })
        .catch(() => {
          // on cancel
        })
    },
    // 滚动条
    getload(e) {
      if (
        (e.target.scrollTop + e.target.offsetHeight).toFixed() >= e.target.scrollHeight &&
        this.hatin
      ) {
        this.getnum++
        this.getlist(null, false)
      }
    },
    // 选中某条内容
    change(item, index) {
      if (item.flag) {
        this.cancel = true
      }
      if (this.select.length === this.getdocument.length) {
        this.operation = true
        this.cancel = false
      }
    },
    // 全选
    bootcanp() {
      this.getdocument.forEach((item) => {
        if (this.operation) {
          item.flag = true
        } else {
          item.flag = false
        }
      })
      if (this.operation) {
        setTimeout(() => {
          this.cancel = false
        })
      }
    },
    // 获取列表
    getlist(val, flag = true) {
      // pageNum 第几页
      // limit 每页数量
      // sortFiled 按创建时间，更新时间，案件进行排序，默认为updateTime
      // sortOrder 排序方式 默认是 DESC
      // caseNames 案件
      // causeNames 案由
      // documentName 文书类型
      // editors 发起人
      // startTime 开始时间
      // endTime 结束时间
      // name 搜索的文书名称
      this.hatin = true
      let url = ''
      // let url = `${caseBase}/document/basicDocuments/getRecentDocByName?pageNum=${this.getnum}&limit=20&name=${val || this.inputsearch.trim()}&sortFiled=${this.type}&`
      if (val || this.inputsearch.trim()) {
        url = `${caseBase}/document/basicDocuments/getRecentDoc3?pageNub=${
          this.getnum
        }&size=20&caseName=${val || this.inputsearch.trim()}&`
      } else {
        console.log('1231321')
        url = `${caseBase}/document/basicDocuments/getRecentDoc3?pageNub=${this.getnum}&size=20&`
      }

      if (
        Object.values(this.screen).filter((item) => {
          return item
        }).length > 0
      ) {
        for (const item in this.screen) {
          url += `${item}=${this.screen[item] || ''}&`
        }
      }
      this.$axios
        .get(url.slice(0, -1))
        .then((res) => {
          if (val) {
            if (val !== this.inputsearch.trim()) {
              return
            }
          }
          if (res.data.data) {
            if (flag) {
              this.$toast('数据加载成功')
            }
            res.data.data.items.forEach((item) => {
              item.flag = false
              const time = new Date(item.lastUpdateTime)
              item.lastUpdateTime = `${time.getFullYear()}.${
                time.getMonth() + 1 < 10
                  ? '0' + (time.getMonth() + 1)
                  : time.getMonth() + 1
              }.${time.getDate() < 10 ? '0' + time.getDate() : time.getDate()}`
            })
            if (res.data.data.items.length === 0) {
              this.hatin = false
              this.$toast('没有更多了')
            }
            this.getdocument = this.getdocument.concat(res.data.data.items)
            // 分配页数，数组数据，历史版本展示
            this.getdocument.forEach((item, index) => {
              this.$set(this.getdocument[index], 'numhis', false)
              this.$set(this.getdocument[index], 'page', 0)
              this.$set(this.getdocument[index], 'data', [])
            })
            // console.log()
          }
        })
        .catch((err) => {
          console.log(err.response)
        })
    },
    creatime(val) {
      this.active = val
      this.getdocument = []
      this.getnum = 1
      if (val === 0) {
        this.type = 'updateTime'
      } else if (val === 1) {
        this.type = 'createtime'
      } else if (val === 2) {
        this.type = 'updateTime'
      } else {
        this.type = 'caseId'
      }
      // if (this.sortOrder === 'DESC') {
      //   this.sortOrder = 'ASC'
      // } else if (this.sortOrder === 'ASC') {
      //   this.sortOrder = 'DESC'
      // }
      this.getlist()
    },
    tocancel() {
      this.showdate = false
    },
    endcancel() {
      this.enddate = false
    },
    confirm(value) {
      const date = new Date(value)
      this.date.startTime = `${date.getFullYear()}-${
        date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      }-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`
      this.showdate = false
    },
    endconfirm(value) {
      const date = new Date(value)
      this.date.endTime = `${date.getFullYear()}-${
        date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      }-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`
      this.enddate = false
    },
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'date') {
        return `${value}日`
      }
      return value
    },
    // 单选
    choosechecked(v) {
      this.is = true
      if (this.result.length !== this.documentList.length) {
        if (this.result.length === 0) {
          this.ischecked = false
        } else {
          this.ischecked = true
        }
        this.fun(false, 2)
      } else {
        this.fun(true, 2)
      }
    },
    fun(bool, type) {
      if (type === 1 && !this.is) {
        if (bool) {
          this.result = this.documentList
          this.call = bool
          this.ischecked = true
        } else {
          this.result = []
          this.call = bool
          this.ischecked = false
        }
      }
      if (type === 2) {
        if (!bool) {
          this.call = bool
        }
        setTimeout(() => {
          this.is = false
        })
      }
    },
    // 输入框失焦返回搜索按钮
    gobutton() {
      this.icon_shai = true
    },
    goback() {
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // 安卓环境
          // eslint-disable-next-line
          AND2JS.back()
        } else if (u === 'fb-iOS-Webview') {
          // ios环境
          // eslint-disable-next-line
          window.webkit.messageHandlers.back.postMessage({})
        } else if (u === 'fb-flutter-Webview') {
          // eslint-disable-next-line
          window.back.postMessage('{}')
        } else {
          history.go(-1)
        }
      } catch (err) {
        console.log('back.postMessage', err)
      }
    },
  },
  mounted() {
    // this.SetCacheCompoment('MyDocument')
    // 筛选接口
    this.$axios
      .get(`${caseBase}/document/basicDocuments/getRecentDocOptionalData`)
      .then((res) => {
        this.datalist = res.data.data
      })
      .catch((err) => {
        console.log(err.response)
      })
    // 获取全部文书
    this.getlist()
    document.body.onclick = (e) => {
      var haha = document.querySelector('.icon-sort')
      if (haha) {
        if (!haha.contains(e.target)) {
          this.sorthidden = false
        }
      }
    }
  },
}
</script>
<style lang="stylus" scoped>
/deep/.main-wrapper
  padding-top 0
.addclass
  color #333
  font-weight 600
.typedata
  width 100%
  /deep/ .van-collapse-item__content
    padding 0px
.catlist
  width 100%
  height 30px
  line-height 30px
  font-size 14px
  display flex
  margin 0px auto
  li:nth-child(1)
    margin-left 15px
    width 35%
  li:nth-child(2)
    width 55%
  li:nth-child(3)
    width 25%
.wenshu
  width 100%
  height 100%
.screen
  width 100%
  font-size 14px
  color #333
  li
    margin-top 15px
    padding-bottom 15px
  li:nth-child(1)
    div
      width 100%
      height 30px
      line-height 30px
      margin-left 15px
.icon-kongbai, .icon-xuanzhong
  font-size 0.5rem
  margin-right 0.2rem
.icon-xuanzhong
  color #4B9EFB
.loading
  width 100%
  height 100%
  position absolute
  // text-align center
  display flex
  // align-items center
  z-index 300
  background whitesmoke
  opacity 0.6
  .van-loading
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
.content
  width 100%
  height calc(100% - 100px)
  overflow auto
  font-size 14px
  color #000000
  .voncent
    width 95%
    margin 0px auto
    // height 100%
    li:nth-child(1)
      width 100%
      height 43px
      line-height 43px
      border-bottom 1px solid #EEEEEE
      display flex
      font-size 14px
      color #000000
      font-weight 400
      div:nth-child(1)
        width 5%
        align-self center
      div:nth-child(2)
        font-size 15px
        color #000
        font-weight 400
        width 55%
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
        margin-left 2%
      div:nth-child(3)
        width 24%
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        margin-left 2%
        font-size 14px
        color rgba(153, 153, 153, 1)
      div:nth-child(4)
        margin-left 3%
        width 10%
        text-align center
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        color #000000
        // color red
.timehistory
  max-height 290px
  width 100%
  background #F7F7F7
  overflow auto
  cursor pointer
  &::-webkit-scrollbar
    /* 滚动条整体样式 */
    width 4px /* 高宽分别对应横竖滚动条的尺寸 */
    height 4px
  &::-webkit-scrollbar-thumb
    /* 滚动条里面小方块 */
    border-radius 5px
    -webkit-box-shadow inset 0 0 5px rgba(0, 0, 0, 0.1)
    background rgba(0, 0, 0, 0.2)
  &::-webkit-scrollbar-track
    /* 滚动条里面轨道 */
    -webkit-box-shadow inset 0 0 5px rgba(0, 0, 0, 0.1)
    border-radius 0
    background rgba(0, 0, 0, 0.1)
  ul
    width 100%
    display flex
    .cirlearea
      position relative
      display flex
      .cirle
        width 35px
        height 35px
        background-color #4b9efb
        border-radius 50%
        display inline-block
        align-self center
        text-align center
        line-height 35px
        color white
        font-size 12px
    li
      height 40px
      line-height 40px
    li:nth-child(1)
      margin-left 0.6rem
      width 56% !important
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
    li:nth-child(2)
      // font-weight bold
      width 21% !important
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
  li:nth-child(3)
    // width 16% !important
    margin-left 0.9rem
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
  li:nth-child(4)
    width 17%
    margin-left 10px
  li:nth-child(5)
    // width 15%
  li:nth-child(6)
    width 6%
    text-align center
.contentlist
  width 95%
  height 40px
  margin 0px auto
  line-height 40px
  display flex
  // justify-content space-between
  li
    align-self center
    color #666 !important
    font-size 15px !important
    height 40px !important
    line-height 40px !important
  li:nth-child(1)
    width 5%
    display flex
    justify-content center
    align-items center
  li:nth-child(2)
    width 55%
    margin-left 2%
    font-size 14px
    min-width 100px
    color #333
    display flex
    justify-content flex-start
    align-items center
  li:nth-child(3)
    width 24%
    margin-left 2%
    font-size 14px
    color #333
    display flex
    justify-content flex-start
    align-items center
  li:nth-child(4)
    margin-left 3%
    min-width 40px
    width 10%
    display flex
    justify-content flex-start
    align-items center
.title
  display flex
  align-items center
  height 40px
  border-bottom 1px solid rgba(243, 244, 247, 1)
  position relative
  color #666666
  .sort
    width 40%
    height 125px
    position absolute
    top 100%
    right 0px
    background #fff
    z-index 999
    background rgba(255, 255, 255, 1)
    box-shadow 0px 4px 8px 1px rgba(190, 190, 190, 0.64)
    border-radius 5px
    li
      width 100%
      height 30px
      line-height 30px
      color #333
      font-size 14px
      text-indent 20px
  .icon-02
    color #333
  .icon-sort
    margin-left 3%
    color #333
  .icon-shaixuan
    color #333
    margin-left 3%
  .arrow
    font-size 0.5rem
    margin-left 0.2rem
  .document
    font-size 0.4rem
    margin-left 0.2rem
    width 19%
  .searchborder
    background #f5f5f5
    height 0.7rem
    align-items center
    width 41%
    display flex
    align-items center
    // margin-left 0.3rem
    border-radius 0.8rem
    input
      border none
      background transparent
      height 99%
      text-indent 10px
#my-document-page
  width 100%
  .function
    display flex
    align-items center
    margin-top 0.3rem
    /deep/ .van-checkbox
      margin-left 0.5rem
      margin-right 0.2rem
    .headicon
      margin-left 0.4rem
    .fun
      width 30%
      display flex
      justify-content space-between
    span
      font-size 0.4rem
  .checkbox
    /deep/ .van-checkbox
      display flex
      align-items center
      padding 0 0.1rem
      margin-top 0.2rem
      border-bottom 1px solid #ccc
      height 0.9rem
      font-weight 900
    /deep/ .van-checkbox__label
      display flex
      align-items center
      div
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      .intro
        width 2.3rem
        padding-right 0.2rem
      .case
        width 3.7rem
        padding-right 0.2rem
</style>
